<template>
  <div class="h-screen flex w-full">
      <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-2/4 xl:w-1/4 mx-auto text-center">
          <vx-card>
              <vs-row class="p-0" slot="no-body">
                  <vs-col class="p-base" vs-xs="12" vs-sm="12" vs-lg="12">
                      <vs-row>
                          <vs-col class="text-center">
                              <img :src="logo" class="login-logo mx-auto" />
                              <h3 class="text-center">Login to your account</h3>
                          </vs-col>
                          <vs-col class="mt-3">
                              <vs-alert class="text-center">
                                  <strong class="block">Invite Only</strong>
                                  <p>
                                      Legully is currently in closed beta with invite only access
                                  </p>
                                  <a href="https://share-eu1.hsforms.com/10AWBbBOiRUW2T9xt2Ztr9w2e2qvy" target="blank">
                                      <vs-button size="small" type="border" class="mt-2">
                                        Request your Invite
                                      </vs-button>
                                  </a>
                              </vs-alert>
                          </vs-col>
                          <vs-col class="mt-base">
                              <vs-input
                                  v-model="email"
                                  type="Email"
                                  label="Email*"
                                  placeholder="Enter your Email"
                                  class="w-full mb-3 text-center"
                                  :danger="api_errors.email ? true : false"
                                  :danger-text="api_errors.email ? api_errors.email.join(',') : null"
                                  @keydown="(e) => {e.keyCode == 13 ? submit() : null}"
                                  :disabled="submit_disabled"
                              />
                          </vs-col>
                          <vs-col class="mb-3">
                              <vs-input
                                  v-model="password"
                                  type="Password"
                                  label="Password*"
                                  placeholder="Enter your Password"
                                  class="w-full text-center"
                                  :danger="api_errors.password ? true : false"
                                  :danger-text="api_errors.password ? api_errors.password.join(',') : null"
                                  @keydown="(e) => {e.keyCode == 13 ? submit() : null}"
                                  :disabled="submit_disabled"
                              />
                              <small class="text-right hidden">
                                  <router-link :to="{name: 'forgot-password'}">
                                      Forgot your password?
                                  </router-link>
                              </small>
                          </vs-col>
                          <vs-col class="mt-3">
                              <vs-button
                                  class="w-full"
                                  :disabled="submit_disabled"
                                  @click.prevent="submit"
                              >
                                  Login
                              </vs-button>
                          </vs-col>
                      </vs-row>
                  </vs-col>
              </vs-row>
          </vx-card>
      </div>
  </div>
</template>

<script>
import { Request } from '../../utils/requests'

export default {
  data () {
      return {
          email: null,
          password: null,

          submit_disabled: false,
          api_errors: {}
      }
  },
  computed: {
      logo () {
          return require('@/assets/images/logo/logo-dark.png')
      }
  },
  mounted () {
      this.$store.dispatch('updateSiteTitle', "Login via Email")

      this.$store.state.auth.isUserLoggedIn().then( res => {
          if (res) this.doPostLogin()
      })
  },
  methods: {
      doPostLogin () {
          if (this.$route.query.next) {
              this.$router.push(this.$route.query.next)
          } else {
              this.$router.push('/')
          }
      },
      submit () {
          this.submit_disabled = true

          const payload = {
              email: this.email,
              password: this.password
          }

          Request(this, 'POST', this.$store.state.apiendpoints.login, null, payload, {}, false).then(
              res => {
                  this.$store.dispatch('auth/updateUserToken', res.token)
                  this.$store.dispatch('auth/updateAuthenticatedUser', res.user)
                  this.$store.dispatch('auth/updateRole', {role: res.role})

                  this.$vs.notify({
                      time: 2500,
                      title: 'Login Success',
                      text: 'Redirecting.. Please wait..',
                      iconPack: 'feather',
                      icon: 'icon-check-circle',
                      color: 'success'
                  })
                  window.location.reload()
              },
              err => {
                  this.submit_disabled = false
                  if (err.error) {
                      this.api_errors = err.error
                      this.$vs.notify({
                          time: 2500,
                          title: 'Login Failed',
                          text: 'Please fix the errors and try again!',
                          iconPack: 'feather',
                          icon: 'icon-alert-circle',
                          color: 'danger'
                      })
                  } else {
                      this.$vs.notify({
                          time: 2500,
                          title: 'API Error',
                          text: 'An Error Occurred. Please try again later.',
                          iconPack: 'feather',
                          icon: 'icon-alert-circle',
                          color: 'danger'
                      })
                  }
              }
          )
      }
  }
}
</script>

<style lang="scss">
  .login-logo {
      max-height: 50px;
  }
  .vs-input.text-center input {
      text-align: center;
  }
</style>
